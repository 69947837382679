// see src/stylesheets/sites/_common.scss
$product-category-header-bg: inherit; // product category header bg color
$product-category-active-filters-bg: inherit; // product category active filters bg color
$product-category-groups-filters-bg: inherit; // product category filter groups bg color
$product-category-rounded: false; // apply a rounded border to product category sections

// override default colors
$dark: #000000;
$warning: #d4c251;
$success: #477d60;
$danger: #733049;

.blackbox{
text-align: center;
color: #ffffff important;
background-color: #000000;
height: 40px;
padding: 10px 0px 10px 0px;
margin: 0px 10px 10px 10px;

}

.greenbox {
  background-color: #e7eae9;
}

// override main menu colors
$menu-bg: #364F45;
$menu-dropdown-bg: #364F45;

// override Campaign outlet button color
$outlet-btn-color: #f49f5c;

// override breadcrumbs colors (fg|display-home MUST be present)
$breadcrumb-fg: inherit; // use `inherit` for default color
$breadcrumb-display-home: inherit; // use `none` to hide home icon, default to `inherit`
// $breadcrumb-bg: inherit; // breadcrumb bg color
// $breadcrumb-divider-color: inherit; // breadcrumb divider item color
// $breadcrumb-active-color: inherit; // breadcrumb active item fg color

// override Campaign buygift button color
// $buygift-btn-color: $dark;

// override route links color
$link-color: #f49f5c;

// override product subcateg#ff4f00ory color
$product-subcategory-bg: #e7e4e4;
$product-subcategory-fg: $dark;

// override product discount ribbon color
$discount-ribbon-bg: #000000;
$discount-ribbon-fg: $white;

// override product-serie-list-item color
$product-series-summary-bg: #dfdad2;
$product-series-summary-fg: #000000;
$product-series-button-bg: #364F45;

.valentines {
  background-color: #df7899 !important;
}

.greybox {
  background-color: #EEEEEE;
}

.monthly {
  background-color: #f49f5c !important;
}

.blackmonth {
  background-color: #000000 !important;
}

.cyber {
  background-color: #007ea6 !important;
}

.neutral {
  background-color: #dfdad2 !important;
}